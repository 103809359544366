import React, { useEffect, useState } from "react";
import "./CurrencyConversion.css";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CurrencyConversionScreen = () => {
  const [currentCurrencyData, setCurrentCurrencyData] = useState(null);
  const [selectedCurrencyData, setSelectedCurrencyData] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);
  const [conversionRate, setConversionRate] = useState(null);
  const [fromCurrency, setFromCurrency] = useState(null);
  const [toCurrency, setToCurrency] = useState(null);

  const [showCancelSuccessToast, setShowCancelSuccessToast] = useState(false);

  useEffect(() => {
    getCurrencyConversionData();
    getCurrencyListData();
  }, []);

  useEffect(() => {
    setSelectedCurrencyData(null);
    if (toCurrency && fromCurrency) {
      currentCurrencyData?.forEach((currencyConversionData) => {
        if (
          currencyConversionData.fromCurrency === fromCurrency &&
          currencyConversionData.toCurrency === toCurrency
        ) {
          setSelectedCurrencyData(currencyConversionData);
        }
      });
    }
  }, [fromCurrency, toCurrency, currentCurrencyData]);

  const getCurrencyListData = () => {
    axios
      .get("https://api.aeroprime.in/crm-service/currency/dropDown", {
        headers: {
          Authorization: localStorage.getItem("AuthToken"),
        },
      })
      .then((response) => setCurrencyList(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const getCurrencyConversionData = () => {
    axios
      .get(
        "https://api.aeroprime.in/crm-service/currency/conversionDetails",
        {
          headers: {
            Authorization: localStorage.getItem("AuthToken"),
          },
        }
      )
      .then((response) => setCurrentCurrencyData(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const updateCurrencyConversion = () => {
    axios
      .post(
        "https://api.aeroprime.in/crm-service/currency/updateConversion",
        {
          id: selectedCurrencyData?.id,
          fromCurrency: fromCurrency,
          toCurrency: toCurrency,
          conversionRate: conversionRate,
        },
        {
          headers: {
            Authorization: localStorage.getItem("AuthToken"),
          },
        }
      )
      .then((response) => {
        if (response?.data?.success === true) {
          getCurrencyConversionData();
          setShowCancelSuccessToast(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  return (
    <div className="currency-conversion-wrapper">
      <Snackbar
        open={showCancelSuccessToast}
        autoHideDuration={3000}
        onClose={() => setShowCancelSuccessToast(false)}
      >
        <Alert
          onClose={() => setShowCancelSuccessToast(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Conversion Rate Updated!!
        </Alert>
      </Snackbar>
      <div className="currency-conversion-header">Currency Conversion</div>
      <div className="currency-conversion-input-wrapper">
        <div className="currency-dd-wrapper">
          <div className="currency-dd">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">From</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fromCurrency}
                label="From"
                onChange={(e) => {
                  setFromCurrency(e.target.value);
                  setToCurrency(null);
                }}
                displayEmpty
                MenuProps={MenuProps}
              >
                {currencyList &&
                  Object.keys(currencyList).map((currency, index) => {
                    return <MenuItem value={currency}>{currency}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="currency-dd">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">To</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={toCurrency}
                label="To"
                onChange={(e) => {
                  setToCurrency(e.target.value);
                }}
                displayEmpty
                MenuProps={MenuProps}
              >
                {currencyList &&
                  fromCurrency &&
                  currencyList[fromCurrency].map((currency, index) => {
                    return <MenuItem value={currency}>{currency}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </div>
        </div>
        {selectedCurrencyData?.conversionRate && (
          <div className="current-currency-rate">{`Current Conversion Rate: ${selectedCurrencyData?.conversionRate}`}</div>
        )}
        <div className="currency-conversion-input">
          <div>
            <FormControl fullWidth>
              <TextField
                type="number"
                min={0}
                label="Conversion Rate"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                value={conversionRate}
                fullWidth
                onChange={(event) => setConversionRate(event.target.value)}
              ></TextField>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="currency-conversion-btn-wrapper">
        <Button
          variant="contained"
          // color="secondary"
          onClick={updateCurrencyConversion}
          disabled={!toCurrency || !fromCurrency || !conversionRate}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default CurrencyConversionScreen;
