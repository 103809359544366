import React, { useEffect, useState } from "react";
import "./EditAgent.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { updateAgentList } from "../../store/slices/agentListSlice";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const permissionWrapper = {
  isSuperAgent: "Is Super Agent",
  canCreateSubAgent: "Can Create Sub Agent",
  canDeactivateSubAgent: "Can Deactivate Sub Agent",
  canViewBookingOfAllSubAgent: "Can View Bookings of All Sub Agent",
  canActivateSubAgentBannerPromotion: "Can Activate Sub Agent Banner Promotion",
  canDoBannerPromotion: "Can Do Banner Promotion",
  canDoPnrManagement: "Can Do PNR Management",
  canViewMisReport: "Can View Booking Report",
  canViewTopUpReport: "Can View Ledger Report",
  canResetSubAgentPassword: "Can Reset Sub Agent Password",
  canCreateBooking: "Can Create Booking",
};

const EditAgentScreen = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [agentDisplayCurrency, setAgentDisplayCurrency] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [showInputErrors, setShowInputErrors] = useState(false);

  const [agentDetails, setAgentDetails] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);

  const dispatch = useDispatch();
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );
  const agentList = useSelector((state) => state.agentList.agentList);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id && agentList) {
      agentList?.forEach((agentDetails) => {
        if (
          (loggedInUserDetails?.role === "admin" &&
            agentDetails?.clientId == id) ||
          (loggedInUserDetails?.role !== "admin" && agentDetails?.id == id)
        ) {
          setName(agentDetails?.name);
          setEmail(agentDetails?.email);
          setPhoneNumber(agentDetails?.phone);
          setPassword(agentDetails?.password);
          setAgentDisplayCurrency(agentDetails?.currencyCode);
          setPermissions({
            isSuperAgent: agentDetails?.isSuperAgent,
            canCreateSubAgent: agentDetails?.canCreateSubAgent,
            canDeactivateSubAgent: agentDetails?.canDeactivateSubAgent,
            canViewBookingOfAllSubAgent:
              agentDetails?.canViewBookingOfAllSubAgent,
            canActivateSubAgentBannerPromotion:
              agentDetails?.canActivateSubAgentBannerPromotion,
            canDoBannerPromotion: agentDetails?.canDoBannerPromotion,
            canDoPnrManagement: agentDetails?.canDoPnrManagement,
            canViewMisReport: agentDetails?.canViewMisReport,
            canViewTopUpReport: agentDetails?.canViewTopUpReport,
            canResetSubAgentPassword: agentDetails?.canResetSubAgentPassword,
            canCreateBooking: agentDetails?.canCreateBooking,
          });
          setAgentDetails(JSON.parse(JSON.stringify(agentDetails)));
        }
      });
    }
  }, [agentList, id]);

  useEffect(() => {
    fetchCurrencyList();
  }, []);

  useEffect(() => {
    if (loggedInUserDetails && !agentList) {
      loggedInUserDetails?.role === "admin"
        ? fetchAgentsListForAdmin()
        : fetchAgentsListForAgent();
    }
  }, [loggedInUserDetails, agentList]);

  const fetchCurrencyList = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/admin/getCurrencyList", {
        headers,
      })
      .then((response) => setCurrencyList(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleToggleChange = (event, permission) => {
    let permissionsObj = JSON.parse(JSON.stringify(permissions));
    permissions[permission] === 0
      ? (permissionsObj[permission] = 1)
      : (permissionsObj[permission] = 0);
    setPermissions(JSON.parse(JSON.stringify(permissionsObj)));
  };

  const fetchAgentsListForAdmin = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/user/details", {
        headers: headersForUserAPI,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAgentList(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchAgentsListForAgent = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/agent/getAgentList", {
        headers: headersForUserAPI,
      })
      .then((response) => dispatch(updateAgentList(response.data)))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleEditAgentForAgentLogin = () => {
    const reqBody = {
      ...agentDetails,
      name: name,
      password: password,
      email: email,
      phone: phoneNumber,
      currencyCode: agentDisplayCurrency,
      ...permissions,
    };
    axios
      .post(
        `https://api.aeroprime.in/crm-service/agent/addUpdate`,
        reqBody,
        {
          headers: {
            Authorization: localStorage.getItem("AuthToken"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          loggedInUserDetails?.role === "admin"
            ? fetchAgentsListForAdmin()
            : fetchAgentsListForAgent();
          navigate("/agents");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleEditAgentForAminLogin = () => {
    const reqBody = {
      ...agentDetails,
      name: name,
      password: password,
      email: email,
      phone: phoneNumber,
      currencyCode: agentDisplayCurrency,
      ...permissions,
    };
    axios
      .post(
        `https://api.aeroprime.in/crm-service/agent/updateSuperAgent`,
        reqBody,
        {
          headers: {
            Authorization: localStorage.getItem("AuthToken"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          loggedInUserDetails?.role === "admin"
            ? fetchAgentsListForAdmin()
            : fetchAgentsListForAgent();
          navigate("/agents");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleEditAgent = () => {
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) ||
      phoneNumber?.length !== 10
    ) {
      setShowInputErrors(true);
    } else {
      loggedInUserDetails?.role === "admin"
        ? handleEditAgentForAminLogin()
        : handleEditAgentForAgentLogin();
    }
  };

  return (
    <div className="add-agent-wrapper">
      <div className="heading">Edit Agent</div>
      <div className="add-agent-section">
        <div className="name-wrapper">
          <FormControl fullWidth>
            <TextField
              type="name"
              label="Name"
              value={name || ''}
              fullWidth
              onChange={(event) => setName(event.target.value)}
              required
            ></TextField>
          </FormControl>
        </div>
        <div className="email-wrapper">
          <FormControl fullWidth>
            <TextField
              type="email"
              label="Email ID"
              value={email || ''}
              fullWidth
              onChange={(event) => setEmail(event.target.value)}
              required
              error={
                showInputErrors &&
                (!email ||
                  !String(email)
                    ?.toLowerCase()
                    ?.match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ))
              }
              disabled
            ></TextField>
          </FormControl>
        </div>
      </div>
      <div className="add-agent-section">
        <div className="phone-wrapper">
          <FormControl fullWidth>
            <TextField
              type="number"
              label="Phone Number"
              value={phoneNumber || ''}
              fullWidth
              onChange={(event) => setPhoneNumber(event.target.value)}
              required
              error={
                showInputErrors && (!phoneNumber || phoneNumber?.length !== 10)
              }
            ></TextField>
          </FormControl>
        </div>
        <div className="password-wrapper">
          <FormControl fullWidth>
            <TextField
              type="password"
              label="Password"
              value={password || ''}
              fullWidth
              onChange={(event) => setPassword(event.target.value)}
              required
              disabled={
                loggedInUserDetails?.can_reset_sub_agent_password === 0
                // (loggedInUserDetails?.role === "admin" &&
                //   loggedInUserDetails?.can_reset_sub_agent_password === 0) ||
                // (loggedInUserDetails?.role !== "admin" &&
                //   loggedInUserDetails?.can_reset_sub_agent_password === 0)
              }
            ></TextField>
          </FormControl>
        </div>
      </div>
      {loggedInUserDetails?.role === "admin" && (
        <div className="add-agent-section">
          <div className="currency-wrapper">
            {agentDisplayCurrency && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Agent Display Currency
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={agentDisplayCurrency}
                  value={agentDisplayCurrency}
                  label="Agent Display Currency"
                  onChange={(event) => {
                    setAgentDisplayCurrency(event.target.value);
                  }}
                  name="Agent Display Currency"
                >
                  {currencyList &&
                    currencyList?.map((currency, index) => {
                      return <MenuItem value={currency}>{currency}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            )}
          </div>
        </div>
      )}
      <div className="add-agent-section permissions-wrapper">
        <div className="permissions-container">
          {permissions &&
            Object.keys(permissions)?.map((permission, index) => {
              if (
                index <= Object.keys(permissions)?.length / 2 &&
                ((loggedInUserDetails?.role === "admin" &&
                  permission !== "isSuperAgent") ||
                  loggedInUserDetails?.role !== "admin")
              ) {
                return (
                  <div key={index + permission}>
                    <span className="switch-container">
                      <Switch
                        checked={permissions[permission] === 1 ? true : false}
                        onChange={(event) =>
                          handleToggleChange(event, permission)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={
                          permission === "isSuperAgent" &&
                          loggedInUserDetails?.role === "sub_agent"
                        }
                      />
                    </span>
                    <span className="switch-label-container">
                      {permissionWrapper[permission]}
                    </span>
                  </div>
                );
              }
            })}
        </div>
        <div className="permissions-container">
          {permissions &&
            Object.keys(permissions)?.map((permission, index) => {
              if (index > Object.keys(permissions)?.length / 2) {
                return (
                  <div key={index + permission}>
                    <span className="switch-container">
                      <Switch
                        checked={permissions[permission] === 1 ? true : false}
                        onChange={(event) =>
                          handleToggleChange(event, permission)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={
                          permission === "isSuperAgent" &&
                          loggedInUserDetails?.role === "sub_agent"
                        }
                      />
                    </span>
                    <span className="switch-label-container">
                      {permissionWrapper[permission]}
                    </span>
                  </div>
                );
              }
            })}
        </div>
      </div>
      <div>
        <Button
          variant="contained"
          // color="secondary"
          onClick={handleEditAgent}
          disabled={!name || !email || !phoneNumber || !password}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default EditAgentScreen;
