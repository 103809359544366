import React, { useEffect, useState } from "react";
import "./TopNavigation.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import settingsIcon from "../../assets/settings_icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateLoggedInUserDetails } from "../../store/slices/loggedInUserDetailsSlice";

const TopNavigation = ({ fetchUserDetails, setFetchUserDetails }) => {
  // const [loggedInUserDetails, setLoggedInUserDetails] = useState(null);
  const dispatch = useDispatch();
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );

  useEffect(() => {
    getLoggedInUserDetails();
  }, []);

  useEffect(() => {
    if (fetchUserDetails) {
      const reqBody = {
        // clientId: 1,
      };
      const headers = {
        "Content-Type": " application/json",
        Authorization: localStorage.getItem("AuthToken"),
      };
      axios
        .post(
          "https://api.aeroprime.in/crm-service/payment/getClientCredits",
          reqBody,
          { headers }
        )
        .then((response) => {
          // dispatch(updateLoggedInUserDetails(response.data))
          // setLoggedInUserDetails(response.data);
          setFetchUserDetails(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/";
          }
        });
    }
  }, [fetchUserDetails]);

  const getLoggedInUserDetails = () => {
    const reqBody = {
      // clientId: 1,
    };
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        "https://api.aeroprime.in/crm-service/payment/getClientCredits",
        reqBody,
        { headers }
      )
      .then((response) => dispatch(updateLoggedInUserDetails(response.data)))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  return (
    <div className="navigation-wrapper">
      <div className="navigation">
        <div className="navigation-content">
          <div className="nav-logo">
            <Link className="navbar-brand" to="/">
              <img
                src="https://aeroprime.in/wp-content/themes/aeroprime/assets/img/svg/darklogo.svg"
                alt="Logo"
              ></img>
            </Link>
          </div>
          <div className="nav-menu">
            <ul className="navbar-menu-container">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Search Flights
                </Link>
              </li>
              {((loggedInUserDetails?.role !== "admin" &&
                (loggedInUserDetails?.can_view_mis_report === 1 ||
                  loggedInUserDetails?.can_view_top_up_report === 1)) ||
                (loggedInUserDetails?.role === "admin" &&
                  (loggedInUserDetails?.can_deactviate_agent_booking_report ===
                    1 ||
                    loggedInUserDetails?.can_deactviate_agent_ledger_report ===
                      1))) && (
                <li className="nav-item">
                  <Link className="nav-link" to="/reports">
                    Reports
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link className="nav-link" to="/agents">
                  Agents
                </Link>
              </li>
              {loggedInUserDetails?.role === "admin" && (
                <li className="nav-item">
                  <Link className="nav-link" to="/admins">
                    Admins
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link className="nav-link" to="/bookingsHistory">
                  Booking History
                </Link>
              </li>
              {loggedInUserDetails?.can_do_pnr_management === 1 && (
                <li className="nav-item">
                  <Link className="nav-link" to="/searchPNR">
                    Search PNR
                  </Link>
                </li>
              )}
              {loggedInUserDetails?.role !== "admin" && (
                <li className="nav-item">
                  <Link className="nav-link" to="/availableBalance">
                    Credit Balance
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right">
            {loggedInUserDetails?.emailId && (
              <div className="nav-username">{`Hello ${
                loggedInUserDetails?.name ?? loggedInUserDetails?.emailId
              }`}</div>
            )}
            {/* {
              loggedInUserDetails?.role !== "admin" && (
                // loggedInUserDetails?.totalAvailableCredits && (
                <div className="nav-balance">{`Credit Balance: ${loggedInUserDetails?.currencySymbol} ${loggedInUserDetails?.
                }`}</div>
              )
              // )
            } */}
            <div className="nav-logout">
              <Button
                // className="btn btn-outline-success"
                className="logout-btn"
                variant="contained"
                color="secondary"
                onClick={() => {
                  localStorage.clear();
                  window.location.href = "/";
                }}
              >
                Logout
              </Button>
            </div>
            {loggedInUserDetails?.role === "admin" && (
              <div className="settings-icon-wrapper">
                <Link to="/currencyConversion">
                  <img src={settingsIcon} alt="Settings" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;
