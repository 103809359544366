import React, { useEffect, useState } from "react";
import "./AdjustMarkup.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";

import backBtn from "../../assets/left-arrow-back.svg";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { useSelector, useDispatch } from "react-redux";
import { updateAgentList } from "../../store/slices/agentListSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AdjustMarkupScreen = () => {
  const [airline, setAirline] = useState(null);
  const [currentAirlineMarkup, setCurrentAirlineMarkup] = useState(null);
  const [markup, setMarkup] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);

  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );
  const agentList = useSelector((state) => state.agentList.agentList);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (airline) {
      agentDetails?.markupList?.forEach((airlineMarkup) => {
        if (airlineMarkup?.airlineCode === airline) {
          setCurrentAirlineMarkup(airlineMarkup);
        }
      });
    }
  }, [airline]);

  const handleDialogOpen = () => {
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    if (!agentList) {
      fetchAgentsListForAdmin();
    }
  }, [agentList]);

  const fetchAgentsListForAdmin = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/user/details", {
        headers: headersForUserAPI,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAgentList(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  useEffect(() => {
    if (agentList && location?.state?.agentDetails?.clientId) {
      agentList?.forEach((agent) => {
        if (agent.email === location.state.agentDetails.email) {
          setAgentDetails(agent);
        }
      });
    }
  }, [agentList, location?.state?.agentDetails?.clientId, agentDetails]);

  const handleAdjustMarkup = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        `https://api.aeroprime.in/airline-service/admin/addMarkup`,
        {
          clientId:
            loggedInUserDetails?.role === "agent"
              ? loggedInUserDetails?.clientId
              : agentDetails?.clientId,
          markupValue: Number(markup),
          airlineCode: currentAirlineMarkup?.airlineCode ?? airline,
          id: currentAirlineMarkup?.id,
        },
        { headers }
      )
      .then((response) => {
        handleDialogClose();
        fetchAgentsListForAdmin();
        navigate("/agents");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  return (
    <div className="adjust-markup-wrapper">
      <div className="back-btn-wrapper">
        <div className="btn-wrapper" onClick={() => navigate("/agents")}>
          <img src={backBtn} alt="" className="back-btn" />
        </div>
      </div>
      <div className="agent-details">
        <div className="details-col">
          <div className="details-field">
            Name: <span>{agentDetails?.name}</span>
          </div>
        </div>
        <div className="details-col">
        <div className="details-field">
            Email: <span>{agentDetails?.email}</span>
          </div>
          {/* <div className="details-field">
            Agent Name: <span>{agentDetails?.clientName}</span>
          </div> */}
          {/* <div className="details-field">
            Current Markup:{" "}
            <span>{`${agentDetails?.markup}%` ?? "Not available"}</span>
          </div> */}
        </div>
      </div>
      {agentDetails?.airlineCodes?.length > 0 ? (
        <div className="adjust-markup-section">
          {agentDetails && (
            <div className="airline-dd">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Airline</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={airline}
                  label="From"
                  onChange={(e) => {
                    setAirline(e.target.value);
                    setCurrentAirlineMarkup(null)
                  }}
                  displayEmpty
                  MenuProps={MenuProps}
                >
                  {agentDetails &&
                    agentDetails?.airlineCodes?.map((airline, index) => {
                      return (
                        <MenuItem value={airline}>
                          {airline}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          )}
          {airline && (
            <div className="current-airline-markup">
              Current Airline Markup:{" "}
              <span>{`${
                currentAirlineMarkup
                  ? `${currentAirlineMarkup?.markupValue}%`
                  : "Not Available"
              }`}</span>
            </div>
          )}
          <div className="adjust-markup-section-element">
            {/* <AmountInput /> */}
            <FormControl fullWidth>
              <TextField
                type="number"
                min={0}
                label="Markup %"
                //   variant="standard"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                value={markup}
                fullWidth
                onChange={(event) => setMarkup(event.target.value)}
              ></TextField>
            </FormControl>
          </div>
          <div className="adjust-markup-section-element">
            <Button
              variant="contained"
              // color="secondary"
              onClick={handleDialogOpen}
              disabled={!airline || !markup}
            >
              Update
            </Button>
          </div>
          <Dialog
            open={showDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to update the markup value?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Disagree</Button>
              <Button onClick={handleAdjustMarkup} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div style={{ marginTop: "25px" }}>No Airlines enabled yet!!</div>
      )}
    </div>
  );
};

export default AdjustMarkupScreen;
