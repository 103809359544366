import React, { useEffect, useState } from "react";
import "./EditAdmin.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { updateAdminList } from "../../store/slices/adminListSlice";

const permissionWrapper = {
  isSuperAdmin: "Is Super Admin",
  canAddBalance: "Can Add Balance",
  canDoPnrManagement: "Can Do PNR Management",
  canModifyBooking: "Can Modify Booking",
  canCancelBooking: "Can Cancel Booking",
  canCreateSuperAgent: "Can Create Admin",
  canCreateSubAgent: "Can Create Agent",
  canDeactivateSuperAgent: "Can Deactivate Admin",
  canDeactivateSubAgent: "Can Deactivate Agent",
  canDeactivateAgentBookingReport: "Can View Agent Booking Report",
  canDeactivateAgentLedgerReport: "Can View Agent Ledger Report",
  canResetSuperAgentPassword: "Can Reset Admin Password",
  canResetSubAgentPassword: "Can Reset Agent Password",
  canActivateAgentBannerPromotion: "Can Activate Agent Banner Promotion",
};

const EditAdminScreen = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [showInputErrors, setShowInputErrors] = useState(false);

  const [adminDetails, setAdminDetails] = useState(null);

  const dispatch = useDispatch();
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );
  const adminList = useSelector((state) => state.adminList.adminList);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id && adminList) {
      adminList?.forEach((adminDetails) => {
        if (adminDetails?.id == id) {
          setName(adminDetails?.name);
          setEmail(adminDetails?.email);
          setPhoneNumber(adminDetails?.phone);
          setPassword(adminDetails?.password);
          setPermissions({
            isSuperAdmin: adminDetails?.isSuperAdmin,
            canAddBalance: adminDetails?.canAddBalance,
            canDoPnrManagement: adminDetails?.canDoPnrManagement,
            canModifyBooking: adminDetails?.canModifyBooking,
            canCancelBooking: adminDetails?.canCancelBooking,
            canCreateSuperAgent: adminDetails?.canCreateSuperAgent,
            canCreateSubAgent: adminDetails?.canCreateSubAgent,
            canDeactivateSuperAgent: adminDetails?.canDeactivateSuperAgent,
            canDeactivateSubAgent: adminDetails?.canDeactivateSubAgent,
            canDeactivateAgentBookingReport:
              adminDetails?.canDeactivateAgentBookingReport,
            canDeactivateAgentLedgerReport:
              adminDetails?.canDeactivateAgentLedgerReport,
            canResetSuperAgentPassword:
              adminDetails?.canResetSuperAgentPassword,
            canResetSubAgentPassword: adminDetails?.canResetSubAgentPassword,
            canActivateAgentBannerPromotion:
              adminDetails?.canActivateAgentBannerPromotion,
          });
          setAdminDetails(JSON.parse(JSON.stringify(adminDetails)));
        }
      });
    }
  }, [adminList, id]);

  useEffect(() => {
    if (!adminList) {
      fetchAdminList();
    }
  }, [adminList]);

  const handleToggleChange = (event, permission) => {
    let permissionsObj = JSON.parse(JSON.stringify(permissions));
    permissions[permission] === 0
      ? (permissionsObj[permission] = 1)
      : (permissionsObj[permission] = 0);
    setPermissions(JSON.parse(JSON.stringify(permissionsObj)));
  };

  const fetchAdminList = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/admin/getAdminList", {
        headers: headersForUserAPI,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAdminList(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleEditAdmin = () => {
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) ||
      phoneNumber?.length !== 10
    ) {
      setShowInputErrors(true);
    } else {
      const reqBody = {
        ...adminDetails,
        name: name,
        password: password,
        email: email,
        phone: phoneNumber,
        ...permissions,
      };
      axios
        .post(
          `https://api.aeroprime.in/crm-service/admin/addUpdate`,
          reqBody,
          {
            headers: {
              Authorization: localStorage.getItem("AuthToken"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            fetchAdminList();
            navigate("/admins");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/";
          }
        });
    }
  };

  return (
    <div className="add-agent-wrapper">
      <div className="heading">Edit Admin</div>
      <div className="add-agent-section">
        <div className="name-wrapper">
          <FormControl fullWidth>
            <TextField
              type="name"
              label="Name"
              value={name || ''}
              fullWidth
              onChange={(event) => setName(event.target.value)}
              required
            ></TextField>
          </FormControl>
        </div>
        <div className="email-wrapper">
          <FormControl fullWidth>
            <TextField
              type="email"
              label="Email ID"
              value={email || ''}
              fullWidth
              onChange={(event) => setEmail(event.target.value)}
              required
              error={
                showInputErrors &&
                (!email ||
                  !String(email)
                    ?.toLowerCase()
                    ?.match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ))
              }
              disabled
            ></TextField>
          </FormControl>
        </div>
      </div>
      <div className="add-agent-section">
        <div className="phone-wrapper">
          <FormControl fullWidth>
            <TextField
              type="number"
              label="Phone Number"
              value={phoneNumber || ''}
              fullWidth
              onChange={(event) => setPhoneNumber(event.target.value)}
              required
              error={
                showInputErrors && (!phoneNumber || phoneNumber?.length !== 10)
              }
            ></TextField>
          </FormControl>
        </div>
        <div className="password-wrapper">
          <FormControl fullWidth>
            <TextField
              type="password"
              label="Password"
              value={password || ''}
              fullWidth
              onChange={(event) => setPassword(event.target.value)}
              required
              disabled={
                loggedInUserDetails?.role === "admin" &&
                loggedInUserDetails?.can_reset_super_agent_password !== 1
              }
            ></TextField>
          </FormControl>
        </div>
      </div>
      <div className="add-agent-section permissions-wrapper">
        <div className="permissions-container">
          {permissions &&
            Object.keys(permissions)?.map((permission, index) => {
              if (index <= Object.keys(permissions)?.length / 2) {
                return (
                  <div key={index + permission}>
                    <span className="switch-container">
                      <Switch
                        checked={permissions[permission] === 1 ? true : false}
                        onChange={(event) =>
                          handleToggleChange(event, permission)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </span>
                    <span className="switch-label-container">
                      {permissionWrapper[permission]}
                    </span>
                  </div>
                );
              }
            })}
        </div>
        <div className="permissions-container">
          {permissions &&
            Object.keys(permissions)?.map((permission, index) => {
              if (index > Object.keys(permissions)?.length / 2) {
                return (
                  <div key={index + permission}>
                    <span className="switch-container">
                      <Switch
                        checked={permissions[permission] === 1 ? true : false}
                        onChange={(event) =>
                          handleToggleChange(event, permission)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </span>
                    <span className="switch-label-container">
                      {permissionWrapper[permission]}
                    </span>
                  </div>
                );
              }
            })}
        </div>
      </div>
      <div>
        <Button
          variant="contained"
          // color="secondary"
          onClick={handleEditAdmin}
          disabled={!name || !email || !phoneNumber || !password}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default EditAdminScreen;
