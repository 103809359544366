import React, { useEffect, useState } from "react";
import "./Home.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import SearchFlights from "../../Components/SearchFlights/SearchFlights";
import ReportsScreen from "../Reports/Reports";
import AgentsScreen from "../Agents/Agents";
import BookingsHistoryScreen from "../BookingsHistory/BookingsHistory";
import Login from "../Login/Login";
import AddBalanceScreen from "../AddBalance/AddBalance";
import AdjustMarkupScreen from "../AdjustMarkup/AdjustMarkup";
import SearchResults from "../../Components/SearchResults/SearchResults";
import SearchPnrScreen from "../SearchPNR/SearchPNR";
import AdjustCreditScreen from "../AdjustCredit/AdjustCredit";
import CurrencyConversionScreen from "../CurrencyConversion/CurrencyConversion";
import AddAgentScreen from "../AddAgent/AddAgent";
import AdminsScreen from "../Admins/Admins";
import AddAdminScreen from "../AddAdmin/AddAdmin";
import EditAgentScreen from "../EditAgent/EditAgent";
import EditAdminScreen from "../EditAdmin/EditAdmin";
import ManageAirlinesScreen from "../ManageAirlines/ManageAirlines";
import DeductBalanceScreen from "../DeductAmount/DeductBalance";
import AvailableBalanceScreen from "../AvailableBalance/AvailableBalance";

const Home = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const [searchResult, setSearchResult] = useState(null);

  const [fetchUserDetails, setFetchUserDetails] = useState(false);

  return (
    <div className="home-wrapper">
      <Router>
        {localStorage.getItem("loggedin") === "true" && (
          <TopNavigation
            fetchUserDetails={fetchUserDetails}
            setFetchUserDetails={setFetchUserDetails}
          />
        )}
        <div
          className={`${
            localStorage.getItem("loggedin") === "true"
              ? "content-container"
              : ""
          }`}
        >
          <Routes>
            <Route path="/reports" element={<ReportsScreen />} />
            <Route path="/agents" element={<AgentsScreen />} />
            <Route path="/addAgent" element={<AddAgentScreen />} />
            <Route path="/editAgent/:id" element={<EditAgentScreen />} />
            <Route path="/admins" element={<AdminsScreen />} />
            <Route path="/addAdmin" element={<AddAdminScreen />} />
            <Route path="/editAdmin/:id" element={<EditAdminScreen />} />
            <Route
              path="/bookingsHistory"
              element={<BookingsHistoryScreen />}
            />
            <Route path="/searchPNR" element={<SearchPnrScreen />} />
            <Route path="/addBalance" element={<AddBalanceScreen />} />
            <Route path="/deductBalance" element={<DeductBalanceScreen />} />
            <Route path="/adjustMarkup" element={<AdjustMarkupScreen />} />
            {/* <Route path="/adjustCredit" element={<AdjustCreditScreen />} /> */}
            <Route path="/availableBalance" element={<AvailableBalanceScreen />} />
            <Route path="/manageAirlines/:clientId" element={<ManageAirlinesScreen />} />
            <Route
              path="/searchResults"
              element={<SearchResults searchResult={searchResult} setFetchUserDetails={setFetchUserDetails}/>}
            />
            <Route
              path="/currencyConversion"
              element={<CurrencyConversionScreen />}
            />
            <Route
              path="/"
              element={
                localStorage.getItem("loggedin") === "true" ? (
                  <SearchFlights setSearchResult={setSearchResult} />
                ) : (
                  <Login setUserLoggedIn={setUserLoggedIn} />
                )
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default Home;
