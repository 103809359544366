import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Login from "./screens/Login/Login";
import Home from "./screens/Home/Home";
import { store } from "./store/store";
import { Provider } from "react-redux";

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(true);

  return (
    <div className="App">
      <Provider store={store}>
        <Home />
      </Provider>
    </div>
  );
}

export default App;
