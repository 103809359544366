import React, { useState, useEffect } from "react";
import "./Reports.css";
import axios from "axios";
import Button from "@mui/material/Button";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";

import { styled } from "@mui/material/styles";

import downloadSvg from "../../assets/download.svg";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#004e89",
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
}));

const ReportsScreen = () => {
  const [agent, setAgent] = useState(null);
  const [airline, setAirline] = useState("All");
  const [origin, setOrigin] = useState("All");
  const [destination, setDestination] = useState("All");
  const [reportType, setReportType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [flightsAvailable, setFlightsAvailable] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [totalResults, setTotalResults] = useState(null);
  const [page, setPage] = useState(1);

  const [firstFetch, setFirstFetch] = useState(true);
  const [agentList, setAgentList] = useState(null);
  const [usersList, setUsersList] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const [airLinesList, setAirlinesList] = useState(null);

  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );

  useEffect(() => {
    fetchOriginList();
    fetchAirlineList();
  }, []);

  useEffect(() => {
    if (loggedInUserDetails?.role === "admin") {
      fetchClientListForLoggedInAdmin();
      fetchUserList();
    }
  }, [loggedInUserDetails]);

  useEffect(() => {
    if (!firstFetch) {
      handleFetchReport();
      setFirstFetch(false);
    }
  }, [page]);

  useEffect(() => {
    if (agent) {
      if (loggedInUserDetails?.role === "admin") {
        usersList?.forEach((user) => {
          if (user?.clientId == agent) {
            setSelectedClient(user);
          }
        });
      } else {
        agentList?.forEach((user) => {
          if (user?.id === agent) {
            setSelectedClient(user);
          }
        });
      }
    }
  }, [agent]);

  useEffect(() => {
    if (loggedInUserDetails?.role !== "admin") {
    }
  }, [loggedInUserDetails]);

  useEffect(() => {
    if (
      (reportType === "bookingReport" ||
      reportType === "failureReport") && loggedInUserDetails?.role !== "admin"
    ) {
      getAgentListForBookingReportDropDown();
    }
    if (
      reportType === "transactionReport" &&
      loggedInUserDetails?.role !== "admin"
    ) {
      getAgentListForLedgerReportDropDown();
    }
  }, [reportType]);

  const fetchAirlineList = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/admin/getAirlineList", {
        headers,
      })
      .then((response) => setAirlinesList(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const getAgentListForBookingReportDropDown = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get(
        "https://api.aeroprime.in/crm-service/agent/getAgentListForBookingReportDropDown",
        {
          headers,
        }
      )
      .then((response) => setAgentList(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const getAgentListForLedgerReportDropDown = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get(
        "https://api.aeroprime.in/crm-service/agent/getAgentListForLedgerReportDropDown",
        {
          headers,
        }
      )
      .then((response) => setAgentList(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchUserList = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/user/details", {
        headers,
      })
      .then((response) => setUsersList(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchOriginList = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/search/originList", {
        headers,
      })
      .then((response) => setFlightsAvailable(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchClientListForLoggedInAdmin = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/client/details", {
        headers,
      })
      .then((response) => setAgentList(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleOriginChange = (event) => {
    const {
      target: { value },
    } = event;
    setOrigin(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setDestination("All");
  };

  const handleDestinationChange = (event) => {
    const {
      target: { value },
    } = event;
    setDestination(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleAirlineChange = (airline) => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    setAirline(airline);
    setOrigin("All");
    setDestination("All");
    axios
      .get(
        `https://api.aeroprime.in/crm-service/search/originList?airlineCode=${airline}`,
        { headers }
      )
      .then((response) => setFlightsAvailable(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleDownloadReport = () => {
    const originCode = Array.isArray(origin) ? origin[0] : "All";
    const destinationCode = Array.isArray(destination) ? destination[0] : "All";
    const clientCode =
      loggedInUserDetails?.role === "agent"
        ? loggedInUserDetails?.clientId
        : agent;
    const startDt = `${startDate["$y"]}-${startDate["$M"] + 1}-${
      startDate["$D"]
    }`;
    let bookingReportUrl =
      loggedInUserDetails?.role === "admin"
        ? `https://api.aeroprime.in/crm-service/download/${reportType}?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&origin=${originCode}&destination=${destinationCode}&tripType=All&clientId=${clientCode}`
        : `https://api.aeroprime.in/crm-service/download/${reportType}?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&origin=${originCode}&destination=${destinationCode}&tripType=All&clientId=${clientCode}&agentId=${selectedClient?.id}`;
    let transactionReportUrl =
      loggedInUserDetails?.role === "admin"
        ? `https://api.aeroprime.in/crm-service/download/${reportType}?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&clientId=${clientCode}`
        : `https://api.aeroprime.in/crm-service/download/${reportType}?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&clientId=${clientCode}&agentId=${selectedClient?.id}`;
    let failedBookingReportUrl =
      loggedInUserDetails?.role === "admin"
        ? `https://api.aeroprime.in/crm-service/download/${reportType}?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&origin=${originCode}&destination=${destinationCode}&tripType=All&clientId=${clientCode}`
        : `https://api.aeroprime.in/crm-service/download/${reportType}?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&origin=${originCode}&destination=${destinationCode}&tripType=All&clientId=${clientCode}&agentId=${selectedClient?.id}`;
    if (reportType === "bookingReport") {
      window.open(bookingReportUrl, "_blank", "noreferrer");
    }
    if (reportType === "transactionReport") {
      window.open(transactionReportUrl, "_blank", "noreferrer");
    }
    if (reportType === "failureReport") {
      window.open(failedBookingReportUrl, "_blank", "noreferrer");
    }
    // const reportData = {
    //   "airlineCode": airline,
    //   origin: origin,
    //   destination: destination
    // }
    // axios
    //   .get(
    //     reportType === "bookingReport"
    //       ? `https://api.aeroprime.in/crm-service/download/${reportType}?startDate=${startDate}&endDate=${endDate}&airlineCode=${airline}&origin=${originCode}&destination=${destinationCode}&tripType=All&clientId=${clientCode}`
    //       : `https://api.aeroprime.in/crm-service/download/${reportType}?startDate=${startDate}&endDate=${endDate}&clientId=${clientCode}`,
    //     { headers: { Authorization: localStorage.getItem("AuthToken") } }
    //   )
    //   .then((response) => console.log(response))
    //   .catch((error) => console.error(error));
  };

  const handleFetchReport = (pageNumber) => {
    setFirstFetch(false);
    const originCode = Array.isArray(origin) ? origin[0] : "All";
    const destinationCode = Array.isArray(destination) ? destination[0] : "All";
    const clientCode =
      loggedInUserDetails?.role === "agent"
        ? loggedInUserDetails?.clientId
        : agent;
    const startDt = `${startDate["$y"]}-${startDate["$M"] + 1}-${
      startDate["$D"]
    }`;
    let bookingReportUrl =
      loggedInUserDetails?.role === "admin"
        ? `https://api.aeroprime.in/crm-service/user/bookingDetails?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&origin=${originCode}&destination=${destinationCode}&tripType=All&clientId=${clientCode}&pageNo=${
            pageNumber ? pageNumber : page
          }&pageSize=10`
        : `https://api.aeroprime.in/crm-service/user/bookingDetails?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&origin=${originCode}&destination=${destinationCode}&tripType=All&clientId=${clientCode}&agentId=${
            selectedClient?.id
          }&pageNo=${pageNumber ? pageNumber : page}&pageSize=10`;
    let transactionReportUrl =
      loggedInUserDetails?.role === "admin"
        ? `https://api.aeroprime.in/crm-service/user/transactionDetails?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&clientId=${clientCode}&pageNo=${
            pageNumber ? pageNumber : page
          }&pageSize=10`
        : `https://api.aeroprime.in/crm-service/user/transactionDetails?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&clientId=${clientCode}&agentId=${
            selectedClient?.id
          }&pageNo=${pageNumber ? pageNumber : page}&pageSize=10`;
    let failedBookingReportUrl =
      loggedInUserDetails?.role === "admin"
        ? `https://api.aeroprime.in/crm-service/user/failureDetails?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&origin=${originCode}&destination=${destinationCode}&tripType=All&clientId=${clientCode}&pageNo=${
            pageNumber ? pageNumber : page
          }&pageSize=10`
        : `https://api.aeroprime.in/crm-service/user/failureDetails?startDate=${startDt}&endDate=${endDate}&airlineCode=${airline}&origin=${originCode}&destination=${destinationCode}&tripType=All&clientId=${clientCode}&agentId=${
            selectedClient?.id
          }&pageNo=${pageNumber ? pageNumber : page}&pageSize=10`;

    let fetchReportsUrl;

    switch (reportType) {
      case "bookingReport":
        fetchReportsUrl = bookingReportUrl;
        break;
      case "transactionReport":
        fetchReportsUrl = transactionReportUrl;
        break;
      case "failureReport":
        fetchReportsUrl = failedBookingReportUrl;
        break;
      default:
        return;
    }

    axios
      .get(fetchReportsUrl, {
        headers: { Authorization: localStorage.getItem("AuthToken") },
      })
      .then((response) => {
        setTableData(response.data.data);
        setTotalResults(response.data.totalCount);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  function createData(
    referenceID,
    paxName,
    tripType,
    bookingID,
    origin,
    destination,
    amount,
    invoiceNO,
    airlineCode,
    paxFirstName,
    paxLastName,
    flightDate,
    agentName
  ) {
    return {
      referenceID,
      paxName,
      tripType,
      bookingID,
      origin,
      destination,
      amount,
      invoiceNO,
      airlineCode,
      paxFirstName,
      paxLastName,
      flightDate,
      agentName,
    };
  }

  const BookingReportrows = tableData?.map((data) => {
    return createData(
      data.referenceID,
      data.paxName,
      data.tripType,
      data.bookingID,
      data.origin,
      data.destination,
      data.amount,
      data.invoiceNO,
      data.airlineCode,
      data.paxFirstName,
      data.paxLastName,
      data.flightDate,
      data.agentName
    );
  });

  function createTansactionData(
    id,
    clientId,
    amount,
    remarks,
    transactionDate,
    airlineCode
  ) {
    return {
      id,
      clientId,
      amount,
      remarks,
      transactionDate,
      airlineCode,
    };
  }

  const TransactionReportrows = tableData?.map((data) => {
    return createTansactionData(
      data.id,
      data.clientId,
      data.amount,
      data.remarks,
      data.transactionDate,
      data.airlineCode
    );
  });

  return (
    <div className="reports-wrapper">
      <div className="heading">Reports</div>
      {loggedInUserDetails?.role === "admin" && (
        <div className="report-client-selection-section">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Agent</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={agent}
              label="Agent"
              onChange={(event) => {
                setAgent(event.target.value);
                setTableData(null);
              }}
              name="Agent"
            >
              {agentList &&
                Object.values(agentList)?.map((client, index) => {
                  const clientIdArray = Object.keys(agentList);
                  return (
                    <MenuItem value={clientIdArray[index]}>{client}</MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      )}
      <div className="report-selection-section">
        <div className="report-type-dd-section">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reportType}
              label="Report Type"
              onChange={(event) => {
                setReportType(event.target.value);
                setPage(1);
                setTableData(null);
              }}
              name="Report Type"
            >
              {((loggedInUserDetails?.role === "admin" &&
                loggedInUserDetails?.can_deactviate_agent_booking_report ===
                  1) ||
                (loggedInUserDetails?.role !== "admin" &&
                  loggedInUserDetails?.can_view_mis_report === 1)) && (
                <MenuItem value="bookingReport">Booking Report</MenuItem>
              )}
              {((loggedInUserDetails?.role === "admin" &&
                loggedInUserDetails?.can_deactviate_agent_ledger_report ===
                  1) ||
                (loggedInUserDetails?.role !== "admin" &&
                  loggedInUserDetails?.can_view_top_up_report === 1)) && (
                <MenuItem value="transactionReport">Ledger Report</MenuItem>
              )}
              {((loggedInUserDetails?.role === "admin" &&
                loggedInUserDetails?.can_deactviate_agent_booking_report ===
                  1) ||
                (loggedInUserDetails?.role !== "admin" &&
                  loggedInUserDetails?.can_view_mis_report === 1)) && (
                <MenuItem value="failureReport">Failed Booking Report</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
      {loggedInUserDetails?.role !== "admin" && reportType && (
        <div className="report-client-selection-section">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Agent</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={agent}
              label="Agent"
              onChange={(event) => {
                setAgent(event.target.value);
                setTableData(null);
              }}
              name="Agent"
            >
              {agentList &&
                Object.values(agentList)?.map((agent, index) => {
                  return <MenuItem value={agent.id}>{agent.name}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </div>
      )}
      <div>
        {/* {reportType === "bookingReport" && ( */}
        <div className="report-airline-section">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Airline</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={airline}
              label="Airline"
              onChange={(event) => handleAirlineChange(event.target.value)}
              name="Airline"
              // disabled={
              //   reportType ? reportType === "transactionReport" : true
              // }
            >
              <MenuItem value="All">All</MenuItem>
              {airLinesList?.map((airline) => (
                <MenuItem value={airline}>{airline}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* )} */}
      </div>
      {reportType === "bookingReport" && (
        <div className="report-city-section">
          <div className="report-origin-section">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Origin</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={origin}
                label="Origin"
                onChange={handleOriginChange}
                name="Origin"
                MenuProps={MenuProps}
                disabled={
                  reportType ? reportType === "transactionReport" : true
                }
              >
                <MenuItem value="All">Any</MenuItem>
                {flightsAvailable &&
                  Object.keys(flightsAvailable).map((city, index) => {
                    return <MenuItem value={city}>{city}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="report-destination-section">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Destination</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={destination}
                label="Destination"
                onChange={handleDestinationChange}
                name="Destination"
                MenuProps={MenuProps}
                disabled={
                  reportType ? reportType === "transactionReport" : true
                }
              >
                <MenuItem value="All">Any</MenuItem>
                {flightsAvailable &&
                  origin &&
                  flightsAvailable?.[`${origin[0]},${origin[1]}`]?.map(
                    (city, index) => {
                      return <MenuItem value={city}>{city}</MenuItem>;
                    }
                  )}
              </Select>
            </FormControl>
          </div>
        </div>
      )}
      <div className="report-dates-section">
        <div className="start-date-picker-section">
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Start Date"
                  onChange={(val) =>
                    // setStartDate()
                    setStartDate(val)
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className="end-date-picker-section">
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="End Date"
                  onChange={(val) =>
                    setEndDate(`${val["$y"]}-${val["$M"] + 1}-${val["$D"]}`)
                  }
                  minDate={startDate}
                  disabled={!startDate}
                />
              </DemoContainer>
            </LocalizationProvider>
          </FormControl>
        </div>
      </div>
      <div className="fetch-report-btn-wrapper">
        <FormControl>
          <Button
            variant="contained"
            onClick={() => handleFetchReport(1)}
            disabled={
              loggedInUserDetails?.role === "admin"
                ? !agent || !reportType || !startDate || !endDate
                : !reportType || !startDate || !endDate
            }
          >
            Fetch Report
          </Button>
        </FormControl>
      </div>
      {tableData && tableData.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                {reportType === "bookingReport" && (
                  <TableRow>
                    <StyledTableCell align="left">Agent</StyledTableCell>
                    <StyledTableCell align="left">Ticket Id</StyledTableCell>
                    <StyledTableCell align="left">PNR No</StyledTableCell>
                    <StyledTableCell align="left">Pax Name</StyledTableCell>
                    <StyledTableCell align="left">Origin</StyledTableCell>
                    <StyledTableCell align="left">Destination</StyledTableCell>
                    <StyledTableCell align="left">Airline Code</StyledTableCell>
                    <StyledTableCell align="left">Flight Date</StyledTableCell>
                    <StyledTableCell align="left">Trip Type</StyledTableCell>
                    <StyledTableCell align="left">
                      {`Amount (In ${
                        loggedInUserDetails?.role === "admin"
                          ? selectedClient?.currencyCode
                          : loggedInUserDetails?.currencyCode
                      })`}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">Invoice No</StyledTableCell> */}
                  </TableRow>
                )}
                {reportType === "transactionReport" && (
                  <TableRow>
                    <StyledTableCell align="left">
                      {`Amount (In ${
                        loggedInUserDetails?.role === "admin"
                          ? selectedClient?.currencyCode
                          : loggedInUserDetails?.currencyCode
                      })`}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Transaction Date
                    </StyledTableCell>
                    <StyledTableCell align="left">Remarks</StyledTableCell>
                    <StyledTableCell align="left">Airline Code</StyledTableCell>
                  </TableRow>
                )}
                {reportType === "failureReport" && (
                  <TableRow>
                    <StyledTableCell align="left">Agent</StyledTableCell>
                    <StyledTableCell align="left">Ticket Id</StyledTableCell>
                    <StyledTableCell align="left">PNR No</StyledTableCell>
                    <StyledTableCell align="left">Pax Name</StyledTableCell>
                    <StyledTableCell align="left">Origin</StyledTableCell>
                    <StyledTableCell align="left">Destination</StyledTableCell>
                    <StyledTableCell align="left">Airline Code</StyledTableCell>
                    <StyledTableCell align="left">Flight Date</StyledTableCell>
                    <StyledTableCell align="left">Trip Type</StyledTableCell>
                    <StyledTableCell align="left">
                      {`Amount (In ${
                        loggedInUserDetails?.role === "admin"
                          ? selectedClient?.currencyCode
                          : loggedInUserDetails?.currencyCode
                      })`}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">Invoice No</StyledTableCell> */}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {reportType === "bookingReport" &&
                  BookingReportrows?.map((row) => (
                    <TableRow
                      key={row.referenceID}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row.agentName}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.referenceID}
                      </TableCell>
                      <TableCell align="left">{row.bookingID}</TableCell>
                      <TableCell align="left">
                        {row.paxName ??
                          `${row.paxFirstName ?? ""} ${row.paxLastName ?? ""}`}
                      </TableCell>
                      <TableCell align="left">{row.origin}</TableCell>
                      <TableCell align="left">{row.destination}</TableCell>
                      <TableCell align="left">{row.airlineCode}</TableCell>
                      <TableCell align="left">{row.flightDate}</TableCell>
                      <TableCell align="left">
                        {row.tripType === "ONE_WAY" ? "One Way" : "Return Trip"}
                      </TableCell>
                      <TableCell align="left">{row.amount}</TableCell>
                      {/* <TableCell align="left">{row.invoiceNO}</TableCell> */}
                    </TableRow>
                  ))}
                {reportType === "transactionReport" &&
                  TransactionReportrows?.map((row) => (
                    <TableRow
                      key={row.referenceID}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.amount}
                      </TableCell>
                      <TableCell align="left">{row.transactionDate}</TableCell>
                      <TableCell align="left">{row.remarks}</TableCell>
                      <TableCell align="left">{row.airlineCode}</TableCell>
                    </TableRow>
                  ))}
                {reportType === "failureReport" &&
                  BookingReportrows?.map((row) => (
                    <TableRow
                      key={row.referenceID}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row.agentName}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.referenceID}
                      </TableCell>
                      <TableCell align="left">{row.bookingID}</TableCell>
                      <TableCell align="left">
                        {row.paxName ??
                          `${row.paxFirstName ?? ""} ${row.paxLastName ?? ""}`}
                      </TableCell>
                      <TableCell align="left">{row.origin}</TableCell>
                      <TableCell align="left">{row.destination}</TableCell>
                      <TableCell align="left">{row.airlineCode}</TableCell>
                      <TableCell align="left">{row.flightDate}</TableCell>
                      <TableCell align="left">
                        {row.tripType === "ONE_WAY" ? "One Way" : "Return Trip"}
                      </TableCell>
                      <TableCell align="left">{row.amount}</TableCell>
                      {/* <TableCell align="left">{row.invoiceNO}</TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(totalResults / 10)}
            color="primary"
            onChange={(e, page) => setPage(page)}
            page={page}
          />
          <div className="download-report-btn-wrapper">
            <FormControl>
              <Button
                variant="contained"
                onClick={handleDownloadReport}
                disabled={
                  loggedInUserDetails?.role === "admin"
                    ? !agent || !reportType || !startDate || !endDate
                    : !reportType || !startDate || !endDate
                }
              >
                <img src={downloadSvg} alt="" className="downloadIcon" />
                Download CSV
              </Button>
            </FormControl>
          </div>
        </>
      )}
      {tableData && tableData.length === 0 && <div>No Records Found!!</div>}
    </div>
  );
};

export default ReportsScreen;
