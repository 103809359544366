import React, { useState, useEffect } from "react";
import axios from "axios";
import "./BookingsHistory.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Pagination from "@mui/material/Pagination";

import { styled } from "@mui/material/styles";
import { updateAgentList } from "../../store/slices/agentListSlice";

import RupeeSvg from "../../assets/rupee-sign-white.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#004e89",
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
}));

const BookingsHistoryScreen = () => {
  const [client, setClient] = useState(null);

  const [bookingHistoryData, setBookingHistoryData] = useState(null);
  const [totalResults, setTotalResults] = useState(null);
  const [page, setPage] = useState(1);

  const [dropdownList, setDropdownList] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );
  const agentList = useSelector((state) => state.agentList.agentList);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(loggedInUserDetails){
      if (loggedInUserDetails?.role === "admin" && !agentList) {
        fetchAgentsListForAdmin();
      }
      if (loggedInUserDetails?.role !== "admin") {
        fetchAgentDroprDownListForAgentLogin();
      }
    }
  }, [loggedInUserDetails]);

  useEffect(() => {
    if (agentList && agentList.length > 0) {
      setClient(agentList[0].clientId);
      setDropdownList(agentList);
    }
  }, [agentList]);

  useEffect(() => {
    if (selectedClient !== null) {
      fetchClientBookingHistory();
    }
  }, [selectedClient, page]);

  useEffect(() => {
    if (client) {
      dropdownList?.forEach((user) => {
        if (
          // (loggedInUserDetails?.role === "admin" &&
          //   user?.clientId === client) ||
          // (loggedInUserDetails?.role !== "admin" && user?.id === client)
          user?.id === client
        ) {
          setSelectedClient(user);
        }
      });
    }
  }, [client]);

  const fetchAgentDroprDownListForAgentLogin = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get(
        "https://api.aeroprime.in/crm-service/agent/getAgentListForBookingDropDown",
        {
          headers: headersForUserAPI,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setDropdownList(response.data);
          setClient(response?.data[0]?.id);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchAgentsListForAdmin = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/user/details", {
        headers: headersForUserAPI,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAgentList(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchClientBookingHistory = () => {
    let reqBody =
      loggedInUserDetails?.role === "admin"
        ? {
            clientId: selectedClient?.clientId,
            agentId: -1,
            pageNo: page,
            pageSize: 10,
          }
        : {
            clientId: selectedClient?.clientId,
            agentId: selectedClient?.id,
            pageNo: page,
            pageSize: 10,
          };
    axios
      .post(
        "https://api.aeroprime.in/crm-service/search/fetchLatestBookings",
        reqBody,
        { headers: { Authorization: localStorage.getItem("AuthToken") } }
      )
      .then((response) => {
        setBookingHistoryData(response.data.data);
        setTotalResults(response.data.totalCount);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  function createData(
    airlineCode,
    amount,
    bookedON,
    bookingID,
    destination,
    origin,
    referenceID,
    tripType
  ) {
    return {
      airlineCode,
      amount,
      bookedON,
      bookingID,
      destination,
      origin,
      referenceID,
      tripType,
    };
  }

  const rows = bookingHistoryData?.map((data) => {
    return createData(
      data.airlineCode,
      data.amount,
      data.bookedON,
      data.bookingID,
      data.destination,
      data.origin,
      data.referenceID,
      data.tripType
    );
  });

  const handleDownloadOfflineBookings = () => {
    window.open(
      `https://api.aeroprime.in/crm-service/admin/downloadOfflineBooking`,
      "_blank",
      "noreferrer"
    );
  };

  return (
    <div className="booking-history-wrapper">
      {loggedInUserDetails?.role === "admin" && (
        <div className="download-offline-bookings-btn-wrapper">
          <Button variant="contained" onClick={handleDownloadOfflineBookings}>
            Download Offline Bookings
          </Button>
        </div>
      )}
      <div className="heading">Booking History</div>
      {dropdownList && (
        <div className="client-selection-section">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Agent</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={client}
              label="Agent"
              onChange={(event) => {
                setClient(event.target.value);
                setPage(1);
              }}
              name="Agent"
            >
              {dropdownList &&
                dropdownList?.map((client, index) => {
                  return (
                    <MenuItem
                      value={
                        // loggedInUserDetails?.role === "admin"
                        //   ? client?.clientId
                        //   :
                        client?.id
                      }
                    >
                      {client?.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      )}
      {bookingHistoryData && bookingHistoryData.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>PNR</StyledTableCell>
                  <StyledTableCell align="left">Ref Id</StyledTableCell>
                  <StyledTableCell align="left">Airline Code</StyledTableCell>
                  <StyledTableCell align="left">Origin</StyledTableCell>
                  <StyledTableCell align="left">Destination</StyledTableCell>
                  <StyledTableCell align="left">Booked On</StyledTableCell>
                  <StyledTableCell align="left">{`Amount (In ${
                    loggedInUserDetails?.role === "admin"
                      ? selectedClient?.currencyCode
                      : loggedInUserDetails?.currencyCode
                  })`}</StyledTableCell>
                  <StyledTableCell align="left">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => (
                  <TableRow
                    key={row.bookingID}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.bookingID}
                    </TableCell>
                    <TableCell align="left">{row.referenceID}</TableCell>
                    <TableCell align="left">{row.airlineCode}</TableCell>
                    <TableCell align="left">{row.origin}</TableCell>
                    <TableCell align="left">{row.destination}</TableCell>
                    <TableCell align="left">{row.bookedON}</TableCell>
                    <TableCell align="left">{row.amount}</TableCell>
                    <TableCell align="left">
                      <div className="actions-wrapper">
                        <Button
                          variant="contained"
                          size="small"
                          className="add-balance-btn"
                          onClick={() =>
                            navigate("/searchPNR", {
                              state: {
                                agentDetails: row,
                              },
                            })
                          }
                          disabled={
                            loggedInUserDetails?.can_do_pnr_management !== 1
                          }
                        >
                          View Details
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalResults % 10 === 0 ? totalResults / 10 : Math.floor(totalResults / 10) + 1}
            color="primary"
            onChange={(e, page) => setPage(page)}
            page={page}
          />
        </>
      )}
      {bookingHistoryData && bookingHistoryData.length < 1 && (
        <div>No Records Found</div>
      )}
    </div>
  );
};

export default BookingsHistoryScreen;
