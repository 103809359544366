import React, { useEffect, useState } from "react";
import "./SearchFlights.css";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

import Loader from "../Loader/Loader";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SearchFlights = ({ setSearchResult }) => {
  const [airline, setAirline] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [tripType, setTripType] = useState("ONE_WAY");
  const [departureDate, setDepartureDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [adult, setAdult] = useState(null);
  const [child, setChild] = useState(null);
  const [infant, setInfant] = useState(null);

  const [flightsAvailable, setFlightsAvailable] = useState(null);
  // const [loggedInUserDetails, setLoggedInUserDetails] = useState(null);

  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const navigate = useNavigate();
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );

  const today = dayjs();

  useEffect(() => {
    setAirline(loggedInUserDetails?.airlineCodes[0]);
  }, [loggedInUserDetails]);

  useEffect(() => {
    if (airline) {
      setShowLoader(true);
      setOrigin(null);
      setDestination(null);
      const headers = {
        Authorization: localStorage.getItem("AuthToken"),
        Accept: "application/json",
      };
      axios
        .get(
          `https://api.aeroprime.in/crm-service/search/originList?airlineCode=${airline}`,
          { headers }
        )
        .then((response) => {
          setFlightsAvailable(response.data);
          setShowLoader(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/";
          }
        });
    }
  }, [airline]);

  const handleAirlineChange = (airline) => {
    setAirline(airline);
  };

  const handleOriginChange = (event) => {
    const {
      target: { value },
    } = event;
    setOrigin(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setDestination(null);
  };

  const handleDestinationChange = (event) => {
    const {
      target: { value },
    } = event;
    setDestination(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSearchFlights = () => {
    setErrorMessage(false);
    setShowLoader(true);
    const originDestinationInformationList = [];
    if (tripType === "ROUND_TRIP") {
      originDestinationInformationList[0] = {
        departureDateTime: `${departureDate["$y"]}-${
          departureDate["$M"] + 1 < 10
            ? `0${departureDate["$M"] + 1}`
            : `${departureDate["$M"] + 1}`
        }-${
          departureDate["$D"] < 10
            ? `0${departureDate["$D"]}`
            : departureDate["$D"]
        }`,
        destinationLocation: destination[0],
        originLocation: origin[0],
      };
      originDestinationInformationList[1] = {
        departureDateTime: `${returnDate["$y"]}-${
          returnDate["$M"] + 1 < 10
            ? `0${returnDate["$M"] + 1}`
            : `${returnDate["$M"] + 1}`
        }-${returnDate["$D"] < 10 ? `0${returnDate["$D"]}` : returnDate["$D"]}`,
        destinationLocation: origin[0],
        originLocation: destination[0],
      };
    } else {
      originDestinationInformationList[0] = {
        departureDateTime: `${departureDate["$y"]}-${
          departureDate["$M"] + 1 < 10
            ? `0${departureDate["$M"] + 1}`
            : `${departureDate["$M"] + 1}`
        }-${
          departureDate["$D"] < 10
            ? `0${departureDate["$D"]}`
            : departureDate["$D"]
        }`,
        destinationLocation: destination[0],
        originLocation: origin[0],
      };
    }

    const reqBody = {
      passengerTypeQuantityList: [
        {
          passengerType: "ADLT",
          quantity: String(adult ?? 0),
        },
        {
          passengerType: "CHLD",
          quantity: String(child ?? 0),
        },
        {
          passengerType: "INFT",
          quantity: String(infant ?? 0),
        },
      ],
      originDestinationInformationList: originDestinationInformationList,
      tripType: tripType,
      currencyCode: "INR",
      airlineCode: airline,
      // islive: "true",
    };

    const headers = {
      Authorization: localStorage.getItem("AuthToken"),
      Accept: "application/json",
    };
    setSearchResult(null);
    axios
      .post(
        `https://api.aeroprime.in/airline-service/getAvailability?airlineCode=${airline}&controlPanel=true`,
        reqBody,
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          setSearchResult({
            ...response.data,
            tripType,
            securityToken: response?.data?.metaData?.securityToken ?? null,
          });
          navigate(
            // `/searchResults/${airline}/${tripType}/${origin}/${destination}/${departureDate}/${returnDate}/${adult}/${child}/${infant}`,
            "/searchResults",
            {
              state: {
                searchRequestBody: reqBody,
                departureDate: departureDate,
                returnDate: returnDate,
                origin: origin,
                destination: destination,
              },
            }
          );
          setShowLoader(false);
        }
        // if (response.status === 401) {
        //   setErrorMessage(true);
        // }
        if (response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch((error) => {
        setShowLoader(false);
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  return (
    <div className="search-wrapper">
      <div className="search-flights-wrapper">
        {showLoader && <Loader hideLoader={false} />}
        <div className="heading">Flight Search</div>
        {loggedInUserDetails?.airlineCodes?.length > 0 ? (
          <>
            <div className="search-airline-section">
              {airline && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Airline</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={airline}
                    label="Airline"
                    onChange={(event) =>
                      handleAirlineChange(event.target.value)
                    }
                    name="Airline"
                  >
                    {/* <MenuItem value="All">All</MenuItem> */}
                    {loggedInUserDetails?.airlineCodes?.map((airline) => (
                      <MenuItem value={airline}>{airline}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="search-trip-section">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={tripType}
                  name="radio-buttons-group"
                  onChange={(e) => setTripType(e.target.value)}
                >
                  <FormControlLabel
                    value="ONE_WAY"
                    control={<Radio />}
                    label="One Way"
                  />
                  <FormControlLabel
                    value="ROUND_TRIP"
                    control={<Radio />}
                    label="Round Trip"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="search-city-section">
              <div className="search-origin-section">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Origin</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={origin}
                    label="Origin"
                    onChange={handleOriginChange}
                    name="Origin"
                    MenuProps={MenuProps}
                  >
                    {flightsAvailable &&
                      Object.keys(flightsAvailable).map((city, index) => {
                        return <MenuItem value={city}>{city}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </div>
              <div className="search-destination-section">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Destination
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={destination}
                    label="Destination"
                    onChange={handleDestinationChange}
                    MenuProps={MenuProps}
                    disabled={!origin}
                  >
                    {flightsAvailable &&
                      origin &&
                      flightsAvailable[`${origin[0]},${origin[1]}`].map(
                        (city, index) => {
                          return <MenuItem value={city}>{city}</MenuItem>;
                        }
                      )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="search-dates-section">
              <div className="search-departure-section">
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Departure Date</InputLabel> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Departure Date"
                        onChange={(val) => setDepartureDate(val)}
                        minDate={today}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              </div>
              {tripType === "ROUND_TRIP" && (
                <div className="search-return-section">
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">Return Date</InputLabel> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Return Date"
                          onChange={(val) => setReturnDate(val)}
                          disabled={!departureDate}
                          minDate={departureDate}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </FormControl>
                </div>
              )}
            </div>
            <div className="add-passenger">
              <div className="add-passenger-type">
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    min={0}
                    label="Adult"
                    InputProps={{
                      inputProps: { min: 0, max: 9 },
                    }}
                    value={adult}
                    fullWidth
                    onChange={(event) => {
                      setAdult(Number(event.target.value));
                      if (infant > event.target.value) {
                        setInfant(Number(event.target.value));
                      }
                    }}
                  ></TextField>
                </FormControl>
              </div>
              <div className="add-passenger-type">
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    min={0}
                    label="Child (2-12 yrs)"
                    InputProps={{
                      inputProps: { min: 0, max: 9 },
                    }}
                    value={child}
                    fullWidth
                    onChange={(event) => setChild(Number(event.target.value))}
                  ></TextField>
                </FormControl>
              </div>
              <div className="add-passenger-type">
                <FormControl fullWidth>
                  <TextField
                    disabled={!adult || adult < 1}
                    type="number"
                    min={0}
                    label="Infant (<2 yrs)"
                    InputProps={{
                      inputProps: { min: 0, max: adult },
                    }}
                    value={infant}
                    fullWidth
                    onChange={(event) => setInfant(Number(event.target.value))}
                  ></TextField>
                </FormControl>
              </div>
            </div>
            <div className="search-btn-wrapper">
              <FormControl>
                <Button
                  variant="contained"
                  onClick={handleSearchFlights}
                  disabled={
                    tripType === "ROUND_TRIP"
                      ? !origin ||
                        !destination ||
                        !departureDate ||
                        !returnDate ||
                        adult + child + infant < 1 ||
                        adult + child + infant > 9
                      : !origin ||
                        !destination ||
                        !departureDate ||
                        adult + child + infant < 1 ||
                        adult + child + infant > 9
                  }
                >
                  Search
                </Button>
              </FormControl>
            </div>
          </>
        ) : (
          <div>No Airlines enabled yet. Search not Allowed !!</div>
        )}
        {errorMessage && (
          <div>Operation Not Allowed. Please contact system admin!</div>
        )}
      </div>
    </div>
  );
};

export default SearchFlights;
