import React, { useEffect, useState } from "react";
import "./AdjustCredit.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";

import backBtn from "../../assets/left-arrow-back.svg";

import { useSelector, useDispatch } from "react-redux";
import { updateAgentList } from "../../store/slices/agentListSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#004e89",
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
}));

const AdjustCreditScreen = () => {
  const [creditLimit, setCreditLimit] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);

  const [currentUserAirlines, setCurrentUserAirlines] = useState(null);
  const [selectedAirline, setSelectedAirline] = useState(null);
  const [airline, setAirline] = useState(null);

  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );
  const agentList = useSelector((state) => state.agentList.agentList);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCurrentUserAirlines();
  }, [location?.state?.agentDetails?.clientId]);

  useEffect(() => {
    if (agentList && location?.state?.agentDetails?.clientId) {
      agentList?.forEach((agent) => {
        if (agent.email === location.state.agentDetails.email) {
          setAgentDetails(agent);
        }
      });
    }
  }, [agentList, location?.state?.agentDetails?.clientId]);

  useEffect(() => {
    if (!agentList) {
      fetchAgentsListForAdmin();
    }
  }, [agentList]);

  const fetchCurrentUserAirlines = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get(
        `https://api.aeroprime.in/crm-service/admin/getAirlineDetails/${location?.state?.agentDetails?.clientId}`,
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          setCurrentUserAirlines(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchAgentsListForAdmin = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/user/details", {
        headers: headersForUserAPI,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAgentList(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  function createAirlineBalanceData(
    airlineCode,
    creditLimit,
    operatingAirlineCurrency
  ) {
    return { airlineCode, creditLimit, operatingAirlineCurrency };
  }

  const airlineBalanceRows = currentUserAirlines?.map((airline) => {
    return createAirlineBalanceData(
      airline.airlineCode,
      airline.creditLimit,
      airline.operatingAirlineCurrency
    );
  });

  const handleDialogOpen = () => {
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleUpdateCreditLimit = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        `https://api.aeroprime.in/airline-service/admin/addCredit`,
        {
          clientId:
            loggedInUserDetails?.role === "agent"
              ? loggedInUserDetails?.clientId
              : agentDetails?.clientId,
          creditValue: Number(creditLimit),
          airlineCode: airline,
        },
        { headers }
      )
      .then((response) => {
        handleDialogClose();
        fetchAgentsListForAdmin();
        navigate("/agents");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  return (
    <div className="adjust-credit-wrapper">
      <div className="back-btn-wrapper">
        <div className="btn-wrapper" onClick={() => navigate("/Agents")}>
          <img src={backBtn} alt="" className="back-btn" />
        </div>
      </div>
      <div className="agent-details">
        <div className="details-col">
          <div className="details-field">
            Name: <span>{agentDetails?.name}</span>
          </div>
        </div>
        <div className="details-col">
          {/* <div className="details-field">
            Agent Name: <span>{agentDetails?.clientName}</span>
          </div> */}
          {/* <div className="details-field">
            Current Credit Limit:{" "}
            <span>{agentDetails?.currencySymbol && agentDetails?.creditLimit ? `${agentDetails?.currencySymbol} ${agentDetails?.creditLimit} ` : "Not available"}</span>
          </div> */}
          <div className="details-field">
            Email: <span>{agentDetails?.email}</span>
          </div>
        </div>
      </div>
      <div className="airline-credit-limit-table-wrapper">
        <div className="airline-credit-limit-table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S.No.</StyledTableCell>
                  <StyledTableCell align="left">Airline</StyledTableCell>
                  <StyledTableCell align="left">Credit Limit</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {airlineBalanceRows?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{row.airlineCode}</TableCell>
                    <TableCell align="left">{`${row.operatingAirlineCurrency} ${row.creditLimit}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="adjust-credit-section">
        <div className="adjust-credit-section-element">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Airline</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={airline}
              label="Airline"
              onChange={(event) => {
                currentUserAirlines?.forEach(airline => {
                  if(airline.airlineCode === event.target.value){
                    setSelectedAirline(airline);
                  }
                })
                setAirline(event.target.value);
              }}
              name="Airline"
            >
              {currentUserAirlines?.map((airline) => (
                <MenuItem value={airline.airlineCode}>
                  {airline.airlineCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="adjust-credit-section-element">
          <FormControl fullWidth>
            <TextField
              type="number"
              min={0}
              label={`Credit Limit ${airline ? `(in ${selectedAirline.operatingAirlineCurrency})` : ""}`}
              InputProps={{
                inputProps: { min: 0 },
              }}
              onKeyPress={(event) => {
                if (event?.key === "-" || event?.key === "+") {
                  event.preventDefault();
                }
              }}
              value={creditLimit}
              fullWidth
              onChange={(event) => setCreditLimit(event.target.value)}
            ></TextField>
          </FormControl>
        </div>
        <div className="adjust-credit-section-element">
          <Button
            variant="contained"
            // color="secondary"
            onClick={handleDialogOpen}
          >
            Update Credit Limit
          </Button>
        </div>
        <Dialog
          open={showDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to update the Credit Limit value?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Disagree</Button>
            <Button onClick={handleUpdateCreditLimit} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default AdjustCreditScreen;
