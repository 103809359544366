import React, { useEffect, useState } from "react";
import "./ManageAirlines.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import backBtn from "../../assets/left-arrow-back.svg";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Delete } from "@mui/icons-material";

import { useDispatch } from "react-redux";
import { updateAgentList } from "../../store/slices/agentListSlice";

const ManageAirlinesScreen = () => {
  const [airLinesList, setAirlinesList] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);
  const [currentUserAirlines, setCurrentUserAirlines] = useState(null);
  const [airlinesAvailableforAddition, setAirlinesAvailableforAddition] =
    useState(null);

  const [showAddAirlineWrapper, setShowAddAirlineWrapper] = useState(false);
  const [airline, setAirline] = useState(null);
  const [currency, setCurrency] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId } = useParams();

  useEffect(() => {
    fetchAirlineList();
    fetchCurrencyList();
  }, []);

  useEffect(() => {
    if (airLinesList) {
      fetchCurrentUserAirlines();
    }
  }, [airLinesList]);

  const fetchAgentsListForAdmin = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/user/details", {
        headers: headersForUserAPI,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAgentList(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchAirlineList = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/admin/getAirlineList", {
        headers,
      })
      .then((response) => setAirlinesList(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchCurrencyList = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/admin/getCurrencyList", {
        headers,
      })
      .then((response) => setCurrencyList(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchCurrentUserAirlines = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get(
        `https://api.aeroprime.in/crm-service/admin/getAirlineDetails/${clientId}`,
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          setCurrentUserAirlines(response.data);
          const existingAirlines = response.data.map(
            (airline) => airline.airlineCode
          );
          let airlinesAvailableforAdditionArr = [];
          airLinesList.forEach((airline) => {
            if (!existingAirlines.includes(airline)) {
              airlinesAvailableforAdditionArr.push(airline);
            }
          });
          setAirlinesAvailableforAddition(airlinesAvailableforAdditionArr);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const addAirline = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        `https://api.aeroprime.in/crm-service/admin/addUpdateAirline`,
        {
          clientId: clientId,
          airlineCode: airline,
          operatingAirlineCurrency: currency,
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          fetchAgentsListForAdmin();
          fetchCurrentUserAirlines();
          setAirline(null)
          setCurrency(null)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const deleteAirline = (airlineId) => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        `https://api.aeroprime.in/crm-service/admin/deleteAirline/${clientId}/${airlineId}`,
        {},
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          fetchCurrentUserAirlines();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const updateCurrencyForAirline = (data) => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        `https://api.aeroprime.in/crm-service/admin/addUpdateAirline`,
        data,
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          fetchCurrentUserAirlines();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  return (
    <div className="manage-airlines-wrapper">
      <div className="back-btn-wrapper">
        <div className="btn-wrapper" onClick={() => navigate("/Agents")}>
          <img src={backBtn} alt="" className="back-btn" />
        </div>
      </div>
      <div className="heading">Manage Airlines</div>
      <div className="current-airlines-currency-wrapper">
        {currentUserAirlines?.map((airline) => {
          return (
            <div className="current-airlines-currency">
              <div className="current-airline">{airline.airlineCode}</div>
              <div className="current-currency">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Operating Currency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={airline.operatingAirlineCurrency}
                    label="Operating Currency"
                    onChange={(event) => {
                      updateCurrencyForAirline({
                        ...airline,
                        operatingAirlineCurrency: event.target.value,
                      });
                    }}
                    name="Operating Currency"
                  >
                    {currencyList &&
                      currencyList?.map((currency, index) => {
                        return <MenuItem value={currency}>{currency}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </div>
              <div className="delete-airline-wrapper">
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => {
                    deleteAirline(airline.id);
                  }}
                >
                  <Delete />
                </Fab>
              </div>
            </div>
          );
        })}
      </div>
      {(airLinesList?.length !== currentUserAirlines?.length &&
        !showAddAirlineWrapper) && (
          <div className="add-airline-btn-wrapper">
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              onClick={() => setShowAddAirlineWrapper(true)}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Airline
            </Fab>
          </div>
        )}
      {showAddAirlineWrapper && (
        <div className="add-airline-wrapper">
          <div className="select-airline">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Airline</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={airline}
                label="Airline"
                onChange={(event) => {
                  setAirline(event.target.value);
                }}
                name="Airline"
              >
                {airlinesAvailableforAddition &&
                  airlinesAvailableforAddition?.map((currency, index) => {
                    return <MenuItem value={currency}>{currency}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="select-currency">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Operating Currency</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currency}
                label="Operating Currency"
                onChange={(event) => {
                  setCurrency(event.target.value);
                }}
                name="Operating Currency"
              >
                {currencyList &&
                  currencyList?.map((currency, index) => {
                    return <MenuItem value={currency}>{currency}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="add-airline">
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              onClick={() => {
                setShowAddAirlineWrapper(false);
                addAirline();
              }}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add
            </Fab>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageAirlinesScreen;
