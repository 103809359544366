import React, { useState, useEffect } from "react";
import "./Admins.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

import { updateAdminList } from "../../store/slices/adminListSlice";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#004e89",
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
}));

function createData(
  name,
  email,
  phone,
  id,
  isActive,
  isSuperAdmin,
  password,
  clientId,
  airlineCodes,
  airlineCurrencyCode,
  canActivateAgentBannerPromotion,
  canAddBalance,
  canCancelBooking,
  canCreateSubAgent,
  canCreateSuperAgent,
  canDeactivateAgentBookingReport,
  canDeactivateAgentLedgerReport,
  canDeactivateSubAgent,
  canDeactivateSuperAgent,
  canDoPnrManagement,
  canModifyBooking,
  canResetSubAgentPassword,
  canResetSuperAgentPassword
) {
  return {
    name,
    email,
    phone,
    id,
    isActive,
    isSuperAdmin,
    password,
    clientId,
    airlineCodes,
    airlineCurrencyCode,
    canActivateAgentBannerPromotion,
    canAddBalance,
    canCancelBooking,
    canCreateSubAgent,
    canCreateSuperAgent,
    canDeactivateAgentBookingReport,
    canDeactivateAgentLedgerReport,
    canDeactivateSubAgent,
    canDeactivateSuperAgent,
    canDoPnrManagement,
    canModifyBooking,
    canResetSubAgentPassword,
    canResetSuperAgentPassword,
  };
}

const AdminsScreen = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminList = useSelector((state) => state.adminList.adminList);
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );

  useEffect(() => {
    if (!adminList) {
      fetchAdminList();
    }
  }, [adminList]);

  const fetchAdminList = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/admin/getAdminList", {
        headers: headersForUserAPI,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAdminList(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const rows = adminList?.map((user) => {
    return createData(
      user.name,
      user.email,
      user.phone,
      user.id,
      user.isActive,
      user.isSuperAdmin,
      user.password,
      user.clientId,
      user.airlineCodes,
      user.airlineCurrencyCode,
      user.canActivateAgentBannerPromotion,
      user.canAddBalance,
      user.canCancelBooking,
      user.canCreateSubAgent,
      user.canCreateSuperAgent,
      user.canDeactivateAgentBookingReport,
      user.canDeactivateAgentLedgerReport,
      user.canDeactivateSubAgent,
      user.canDeactivateSuperAgent,
      user.canDoPnrManagement,
      user.canModifyBooking,
      user.canResetSubAgentPassword,
      user.canResetSuperAgentPassword
    );
  });

  const deleteAdmin = (id) => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        "https://api.aeroprime.in/crm-service/admin/delete",
        { id: id },
        {
          headers: headersForUserAPI,
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          fetchAdminList();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleDisableAdmin = () => {
    const reqBody = {
      ...selectedUserInfo,
      isActive: selectedUserInfo?.isActive === 1 ? 0 : 1,
    };
    axios
      .post(
        `https://api.aeroprime.in/crm-service/admin/addUpdate`,
        reqBody,
        {
          headers: {
            Authorization: localStorage.getItem("AuthToken"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          fetchAdminList();
          handleClose();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleClickOpen = (user) => {
    setSelectedUserInfo(user);
    setShowDialog(true);
  };

  const handleClose = () => {
    setSelectedUserInfo(null);
    setShowDialog(false);
  };

  return (
    <div className="users-wrapper">
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`${
              (loggedInUserDetails?.role === "admin" &&
                selectedUserInfo?.active) ||
              selectedUserInfo?.isActive
                ? "Do you want to disable this user? Disabled Admin cannot make transactions"
                : "Do you want to enable this user? Enabled Admin can make transactions"
            }`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleDisableAdmin} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <div className="register-btn-wrapper">
        <Button
          variant="contained"
          onClick={() => navigate("/addAdmin")}
          disabled={
            (loggedInUserDetails?.role === "admin" &&
              loggedInUserDetails?.can_create_super_agent !== 1) ||
            (loggedInUserDetails?.role !== "admin" &&
              loggedInUserDetails?.can_create_super_agent !== 1)
          }
        >
          Add Admin
        </Button>
      </div>
      <div className="heading">Admin List</div>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Phone Number</StyledTableCell>
                <StyledTableCell align="left">
                  Enable/Disable Agent
                </StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.phone}</TableCell>
                    <TableCell align="center">
                      {loggedInUserDetails.emailId !== row.email && (
                        <FormControlLabel
                          control={
                            <Switch
                              defaultChecked={row.isActive}
                              disabled={
                                loggedInUserDetails?.can_deactivate_super_agent !==
                                1
                              }
                              checked={row.isActive}
                              onChange={(e) => handleClickOpen(row)}
                            />
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {loggedInUserDetails.emailId !== row.email && (
                        <IconButton
                          aria-label="delete-agent"
                          color="primary"
                          onClick={() => navigate(`/editAdmin/${row.id}`)}
                          disabled={
                            (loggedInUserDetails?.role === "admin" &&
                              loggedInUserDetails?.can_create_super_agent !==
                                1) ||
                            (loggedInUserDetails?.role !== "admin" &&
                              loggedInUserDetails?.can_create_super_agent !== 1)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                      {loggedInUserDetails.emailId !== row.email && (
                        <IconButton
                          aria-label="delete-agent"
                          color="primary"
                          onClick={() => deleteAdmin(row.id)}
                          disabled={
                            (loggedInUserDetails?.role === "admin" &&
                              loggedInUserDetails?.can_create_super_agent !==
                                1) ||
                            (loggedInUserDetails?.role !== "admin" &&
                              loggedInUserDetails?.can_create_super_agent !== 1)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AdminsScreen;
