import React, { useState, useEffect } from "react";
import "./Agents.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateAgentList } from "../../store/slices/agentListSlice";
import axios from "axios";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { TableVirtuoso } from "react-virtuoso";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#004e89",
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
}));

function createDataForAdmin(
  name,
  email,
  role,
  clientName,
  active,
  balance,
  markup,
  clientId,
  creditLimit,
  totalAvailableCredits,
  currencySymbol,
  currencyCode
) {
  const roleMapper = {
    super_admin: "Super Admin",
    admin: "Admin",
    agent: "Agent",
  };
  let roleDisplay = roleMapper[role];
  return {
    name,
    email,
    roleDisplay,
    clientName,
    active,
    balance,
    markup,
    clientId,
    creditLimit,
    totalAvailableCredits,
    currencySymbol,
    currencyCode,
  };
}

function createDataForAgent(
  name,
  email,
  clientId,
  phone,
  id,
  isActive,
  password,
  canActivateSubAgentBannerPromotion,
  canCreateBooking,
  canCreateSubAgent,
  canDeactivateSubAgent,
  canDoBannerPromotion,
  canDoPnrManagement,
  canResetSubAgentPassword,
  canViewBookingOfAllSubAgent,
  canViewMisReport,
  canViewTopUpReport,
  isSuperAgent,
  isMasterAgent
) {
  return {
    name,
    email,
    clientId,
    phone,
    id,
    isActive,
    password,
    canActivateSubAgentBannerPromotion,
    canCreateBooking,
    canCreateSubAgent,
    canDeactivateSubAgent,
    canDoBannerPromotion,
    canDoPnrManagement,
    canResetSubAgentPassword,
    canViewBookingOfAllSubAgent,
    canViewMisReport,
    canViewTopUpReport,
    isSuperAgent,
    isMasterAgent,
  };
}

const AgentsScreen = () => {
  // const [loggedInUserDetails, setLoggedInUserDetails] = useState(null);
  // const [usersList, setUsersList] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.loggedInUserDetails
  );
  const agentList = useSelector((state) => state.agentList.agentList);

  useEffect(() => {
    if (loggedInUserDetails && !agentList) {
      loggedInUserDetails?.role === "admin"
        ? fetchAgentsListForAdmin()
        : fetchAgentsListForAgent();
    }
  }, [loggedInUserDetails, agentList]);

  const fetchAgentsListForAdmin = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/user/details", {
        headers: headersForUserAPI,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAgentList(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const fetchAgentsListForAgent = () => {
    const headersForUserAPI = {
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .get("https://api.aeroprime.in/crm-service/agent/getAgentList", {
        headers: headersForUserAPI,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAgentList(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const agentListForAdminRows = agentList?.map((user) => {
    return createDataForAdmin(
      user.name,
      user.email,
      user.role,
      user.clientName ?? "-",
      user.active,
      user.balance ?? "Not Available",
      user.markup ?? "Not Available",
      user.clientId,
      user.creditLimit ?? "Not Available",
      user.totalAvailableCredits,
      user.currencySymbol,
      user.currencyCode
    );
  });

  const agentListForAgentRows = agentList?.map((user) => {
    return createDataForAgent(
      user.name,
      user.email,
      user.clientId,
      user.phone,
      user.id,
      user.isActive,
      user.password,
      user.canActivateSubAgentBannerPromotion,
      user.canCreateBooking,
      user.canCreateSubAgent,
      user.canDeactivateSubAgent,
      user.canDoBannerPromotion,
      user.canDoPnrManagement,
      user.canResetSubAgentPassword,
      user.canViewBookingOfAllSubAgent,
      user.canViewMisReport,
      user.canViewTopUpReport,
      user.isSuperAgent,
      user.isMasterAgent
    );
  });

  const handleDisableUser = () => {
    if (loggedInUserDetails?.role === "admin") {
      handleDisableAgentForAdminLogin();
    } else {
      handleEditAgentForAgentLogin();
    }
  };

  const handleDisableAgentForAdminLogin = () => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        `https://api.aeroprime.in/crm-service/user/disableAgent?agentEmailId=${selectedUserInfo.email}`,
        {
          active: !selectedUserInfo.active,
        },
        { headers }
      )
      .then((response) => {
        dispatch(updateAgentList(response.data));
        handleClose();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleEditAgentForAgentLogin = () => {
    const reqBody = {
      ...selectedUserInfo,
      isActive: selectedUserInfo?.isActive === 1 ? 0 : 1,
    };
    axios
      .post(
        `https://api.aeroprime.in/crm-service/agent/addUpdate`,
        reqBody,
        {
          headers: {
            Authorization: localStorage.getItem("AuthToken"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          fetchAgentsListForAgent();
          handleClose();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const handleClickOpen = (user) => {
    setSelectedUserInfo(user);
    setShowDialog(true);
  };

  const handleClose = () => {
    setSelectedUserInfo(null);
    setShowDialog(false);
  };

  const deleteAgent = (id) => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        "https://api.aeroprime.in/crm-service/agent/delete",
        { id: id },
        { headers }
      )
      .then((response) => {
        if (response.data.success === true) {
          loggedInUserDetails?.role === "admin"
            ? fetchAgentsListForAdmin()
            : fetchAgentsListForAgent();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const deleteSuperAgent = (id) => {
    const headers = {
      "Content-Type": " application/json",
      Authorization: localStorage.getItem("AuthToken"),
    };
    axios
      .post(
        "https://api.aeroprime.in/crm-service/agent/deleteSuperAgent",
        { clientId: id },
        { headers }
      )
      .then((response) => {
        if (response.data.success === true) {
          loggedInUserDetails?.role === "admin"
            ? fetchAgentsListForAdmin()
            : fetchAgentsListForAgent();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  if (loggedInUserDetails?.role === "admin") {
    return (
      <div className="users-wrapper">
        <div className="register-btn-wrapper">
          <Button
            variant="contained"
            onClick={() => navigate("/addAgent")}
            disabled={
              (loggedInUserDetails?.role !== "admin" &&
                loggedInUserDetails?.can_create_sub_agent !== 1) ||
              (loggedInUserDetails?.role === "admin" &&
                loggedInUserDetails?.can_create_sub_agent !== 1)
            }
          >
            Add Agent
          </Button>
        </div>
        <div className="heading">Agents List</div>
        <div>
          <Dialog
            open={showDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`${
                  (loggedInUserDetails?.role === "admin" &&
                    selectedUserInfo?.active) ||
                  selectedUserInfo?.isActive
                    ? "Do you want to disable this user? Disabled Agent cannot make transactions"
                    : "Do you want to enable this user? Enabled Agent can make transactions"
                }`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleDisableUser} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  {/* <StyledTableCell align="left">Role</StyledTableCell> */}
                  <StyledTableCell align="left">Agent Name</StyledTableCell>
                  {/* <StyledTableCell align="left">
                    Available Credit
                  </StyledTableCell> */}
                  {/* <StyledTableCell align="left">Markup Value</StyledTableCell> */}
                  {/* <StyledTableCell align="left">Credit Limit</StyledTableCell> */}
                  <StyledTableCell align="left">
                    Enable/Disable Agent
                  </StyledTableCell>
                  <StyledTableCell align="left">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agentListForAdminRows?.map((row) => {
                  if (row.name !== "Admin") {
                    return (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        {/* <TableCell align="left">{row.roleDisplay}</TableCell> */}
                        <TableCell align="left">{row.clientName}</TableCell>
                        {/* <TableCell align="left">{`${
                          row.currencySymbol && row.totalAvailableCredits
                            ? `${row.currencySymbol} ${row.totalAvailableCredits}`
                            : `${row.currencySymbol} 0`
                        }`}</TableCell> */}
                        {/* <TableCell align="left">{`${row.markup}%`}</TableCell> */}
                        {/* <TableCell align="left">{`${
                          row.currencySymbol && row.creditLimit
                            ? `${row.currencySymbol} ${row.creditLimit}`
                            : `${row.currencySymbol} 0`
                        }`}</TableCell> */}
                        <TableCell align="center">
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={row.active}
                                disabled={
                                  loggedInUserDetails?.can_deactivate_sub_agent !==
                                  1
                                }
                                checked={row.active}
                                onChange={(e) => handleClickOpen(row)}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell align="left">
                          <div className="actions-wrapper">
                            <div className="edit-delete-wrapper">
                              <IconButton
                                aria-label="edit-agent"
                                color="primary"
                                onClick={() =>
                                  navigate(`/editAgent/${row.clientId}`)
                                }
                                disabled={
                                  loggedInUserDetails?.can_create_sub_agent !==
                                  1
                                }
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                aria-label="delete-agent"
                                color="primary"
                                onClick={() => deleteSuperAgent(row.clientId)}
                                disabled={
                                  loggedInUserDetails?.can_create_sub_agent !==
                                  1
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                            <Button
                              variant="contained"
                              size="small"
                              className="add-balance-btn"
                              onClick={() =>
                                navigate("/addBalance", {
                                  state: {
                                    agentDetails: row,
                                  },
                                })
                              }
                              disabled={
                                loggedInUserDetails?.can_add_balance !== 1
                              }
                            >
                              Update Balance
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              className="deduct-balance-btn"
                              onClick={() =>
                                navigate("/deductBalance", {
                                  state: {
                                    agentDetails: row,
                                  },
                                })
                              }
                              disabled={
                                loggedInUserDetails?.can_add_balance !== 1
                              }
                            >
                              Deduct Balance
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              className="adjust-markup-btn"
                              onClick={() =>
                                navigate("/adjustMarkup", {
                                  state: {
                                    agentDetails: row,
                                  },
                                })
                              }
                              disabled={
                                loggedInUserDetails?.can_add_balance !== 1
                              }
                            >
                              Adjust Markup
                            </Button>
                            {/* <Button
                              variant="contained"
                              size="small"
                              className="adjust-credit-btn"
                              onClick={() =>
                                navigate("/adjustCredit", {
                                  state: {
                                    agentDetails: row,
                                  },
                                })
                              }
                            >
                              Adjust Credit Limit
                            </Button> */}
                            <Button
                              variant="contained"
                              size="small"
                              className="adjust-credit-btn"
                              onClick={() =>
                                navigate(`/manageAirlines/${row.clientId}`)
                              }
                            >
                              Manage Airlines
                            </Button>
                            {/* <Button
                                variant="contained"
                                size="small"
                                className="delete-agent-btn"
                                onClick={() => deleteSuperAgent(row.clientId)}
                              >
                                Delete Agent
                              </Button> */}
                            {/* <Button
                                variant="contained"
                                size="small"
                                className="edit-agent-btn"
                                onClick={() =>
                                  navigate(`/editAgent/${row.clientId}`)
                                }
                                disabled={
                                  loggedInUserDetails?.can_create_sub_agent !==
                                  1
                                }
                              >
                                Edit Agent
                              </Button> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }

  return (
    <div className="users-wrapper">
      <div className="register-btn-wrapper">
        <Button
          variant="contained"
          onClick={() => navigate("/addAgent")}
          disabled={loggedInUserDetails?.can_create_sub_agent !== 1}
        >
          Add Agent
        </Button>
      </div>
      <div className="heading">Agents List</div>
      <div className="sub-heading">Super Agents</div>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Phone Number</StyledTableCell>
                {/* <StyledTableCell align="left">Agent Type</StyledTableCell> */}
                <StyledTableCell align="left">
                  Enable/Disable Agent
                </StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agentListForAgentRows?.map((row) => {
                if (row.isSuperAgent === 1) {
                  return (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      {/* <TableCell align="left">
                        {row.isSuperAgent === 1 ? "Super Agent" : "Sub Agent"}
                      </TableCell> */}
                      <TableCell align="center">
                        {loggedInUserDetails.emailId !== row.email && (
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={row.isActive}
                                disabled={
                                  loggedInUserDetails?.can_deactivate_sub_agent !==
                                  1
                                }
                                checked={row.isActive}
                                onChange={(e) => handleClickOpen(row)}
                              />
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {loggedInUserDetails.emailId !== row.email &&
                          row.isMasterAgent !== 1 && (
                            <IconButton
                              aria-label="edit-agent"
                              color="primary"
                              onClick={() => navigate(`/editAgent/${row.id}`)}
                              disabled={
                                loggedInUserDetails?.can_create_sub_agent !== 1
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        {loggedInUserDetails.emailId !== row.email &&
                          row.isMasterAgent !== 1 && (
                            <IconButton
                              aria-label="delete-agent"
                              color="primary"
                              onClick={() => deleteAgent(row.id)}
                              disabled={
                                loggedInUserDetails?.can_create_sub_agent !== 1
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="sub-heading">Sub Agents</div>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Phone Number</StyledTableCell>
                {/* <StyledTableCell align="left">Agent Type</StyledTableCell> */}
                <StyledTableCell align="left">
                  Enable/Disable Agent
                </StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agentListForAgentRows?.map((row) => {
                if (row.isSuperAgent !== 1) {
                  return (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      {/* <TableCell align="left">
                        {row.isSuperAgent === 1 ? "Super Agent" : "Sub Agent"}
                      </TableCell> */}
                      <TableCell align="center">
                        {loggedInUserDetails.emailId !== row.email && (
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={row.isActive}
                                disabled={
                                  loggedInUserDetails?.can_deactivate_sub_agent !==
                                  1
                                }
                                checked={row.isActive}
                                onChange={(e) => handleClickOpen(row)}
                              />
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {loggedInUserDetails.emailId !== row.email &&
                          row.isMasterAgent !== 1 && (
                            <IconButton
                              aria-label="edit-agent"
                              color="primary"
                              onClick={() => navigate(`/editAgent/${row.id}`)}
                              disabled={
                                loggedInUserDetails?.can_create_sub_agent !== 1
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        {loggedInUserDetails.emailId !== row.email &&
                          row.isMasterAgent !== 1 && (
                            <IconButton
                              aria-label="delete-agent"
                              color="primary"
                              onClick={() => deleteAgent(row.id)}
                              disabled={
                                loggedInUserDetails?.can_create_sub_agent !== 1
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`${
              (loggedInUserDetails?.role === "admin" &&
                selectedUserInfo?.active) ||
              selectedUserInfo?.isActive
                ? "Do you want to disable this user? Disabled Agent cannot make transactions"
                : "Do you want to enable this user? Enabled Agent can make transactions"
            }`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleDisableUser} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AgentsScreen;
