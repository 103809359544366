import React, { useEffect, useState } from "react";
import "./Login.css";
// import {
//   Checkbox,
//   Grid,
//   TextField,
//   FormControlLabel,
//   Paper,
//   Button
// } from '@material-ui/core';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import loginLogo from "../../assets/user-login.png";
import Alert from "@mui/material/Alert";

const Login = ({ setUserLoggedIn }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  // const navigate = useNavigate();

  const handleLogin = () => {
    setShowError(false);
    const loginData = {
      email: userName,
      password: password,
    };
    const headers = {
      "Content-Type": " application/json",
    };
    axios
      .post("https://api.aeroprime.in/crm-service/user/login", loginData, {
        headers,
      })
      .then((response) => {
        setUserLoggedIn(true);
        localStorage.setItem("AuthToken", `Bearer ${response.data.token}`);
        localStorage.setItem("loggedin", `true`);
        // navigate('/home')
      })
      .catch((error) => {
        setShowError(true);
      });
  };

  const handleKeyDown = (e) => {
    if(e.keyCode === 13){
      handleLogin();
    }
  }


  useEffect(() =>{

    document.addEventListener('keydown', handleKeyDown);

    return () => {
        document.removeEventListener('keydown', handleKeyDown);
    };

  })

  return (
    <div className="login-wrapper">
      <div className="login">
        <Grid
          container
          spacing={3}
          direction={"column"}
          justify={"center"}
          alignItems={"center"}
        >
          <div className="login-logo">
            <img src={loginLogo} alt="log in" />
          </div>
          <div className="log-in-header">Log In</div>
          <div className="login-sections input-fields">
            <TextField
              label="Username"
              variant="standard"
              fullWidth
              onChange={(event) => setUserName(event.target.value)}
            ></TextField>
          </div>
          <div className="login-sections input-fields">
            <TextField
              label="Password"
              type={"password"}
              variant="standard"
              fullWidth
              onChange={(event) => setPassword(event.target.value)}
            ></TextField>
          </div>
          {showError && <div className="login-sections-error">
            <Alert severity="error">
              Login Failed! Please Try with correct username and password!
            </Alert>
          </div>}
          <div className="login-sections">
            <Button fullWidth className="login-btn" onClick={handleLogin}>
              Login
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default Login;
